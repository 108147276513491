import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Location, useListLocations } from '../../../app/ApiGen';
import { singleLineAddress } from '../../../components/Address';
import { ColumnFiltersToggle, getToolbarSearch, RefreshButton } from '../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../components/ErrorHandler';
import { ExportCsv, ExportProps } from '../../../components/ExportCsv';
import { TableLink } from '../../../components/Link';
import { ColumnChanger, ColumnChangerProps, DataTable, Workspace } from '../../../design-system';
import { navigateOnRowClick } from '../../../utils/dataTable/rowHelpers';
import { organisationHasAttributes } from '../../../utils/format';
import { normalise, Normalised } from '../../../utils/request';
import { addressIncludesSearch } from '../../../utils/search';
import { dateFormat } from '../../../utils/sessions/columns/format';
import { sortByAddress, sortByLocale } from '../../../utils/sortBy';
import { csvDateFormat } from '../../Organisations/Organisation/Reports/utils';
import { LocationMap } from './LocationMap';
import type { LocationData } from './types';

const toRowData = (locations: Normalised<Location>[]): LocationData[] =>
  locations.map((location) => {
    const { id, name, address, chargePoints, organisation, created, updated, coordinates, icpNumber, icpDetails } =
      location;
    const { country } = address;
    const orgHasAttributes = organisationHasAttributes(organisation);
    const organisationLabel = orgHasAttributes ? organisation.name : organisation?.id ?? '';
    const organisationSlug = orgHasAttributes ? organisation.slug : '';
    return {
      id,
      name,
      address,
      country,
      chargePointCount: chargePoints.length,
      organisationName: organisationLabel,
      organisationSlug,
      createdDate: created,
      updatedDate: updated,
      coordinates,
      icpNumber,
      gridExitPoint: icpDetails?.networkConnectionPoint,
      electricityDistributor: icpDetails?.electricityDistributor,
      electricityRetailer: icpDetails?.electricityRetailer,
    };
  });

export const LocationsList: React.FC = () => {
  const [filtering, setFiltering] = React.useState(false);

  const history = useHistory();
  const { loading, error, data, refetch } = useListLocations({});

  if (error) {
    return <DataErrorHandler refetch={refetch} description="Unable to load locations" error={error} />;
  }

  const locations = data ? toRowData(normalise(data)) : [];
  const getLocationUrl = (rowData: LocationData) =>
    `/organisations/${rowData.organisationSlug}/locations/${rowData.id}`;

  return (
    <Workspace maxWidth="xl">
      <Card>
        {locations && <LocationMap locations={locations} />}
        <DataTable<LocationData>
          isLoading={loading}
          options={{ columnsButton: true, filtering }}
          toolbarProps={{
            ...getToolbarSearch('locations'),
            actions: (props: React.PropsWithChildren<ColumnChangerProps> & ExportProps<LocationData>) => (
              <>
                <ColumnChanger
                  columnsButton={props.columnsButton}
                  columns={props.columns}
                  icons={props.icons}
                  onColumnsChanged={props.onColumnsChanged}
                />
                <ColumnFiltersToggle filtering={filtering} setFiltering={setFiltering} />
                <RefreshButton refetch={refetch} />
                <ExportCsv<LocationData>
                  columns={props.columns}
                  data={props.data}
                  dateFormat={csvDateFormat}
                  exportFileName="all-locations"
                  getFieldValue={props.getFieldValue}
                  icons={props.icons}
                />
                <Button component={Link} to="/admin/migrate/locations" color="primary" variant="contained">
                  Move locations
                </Button>
              </>
            ),
          }}
          columns={[
            {
              field: 'name',
              title: 'Name',
              defaultSort: 'asc',
              customSort: sortByLocale('name'),
              render: (rowData) => <TableLink to={getLocationUrl(rowData)}>{rowData.name}</TableLink>,
            },
            {
              field: 'address',
              title: 'Address',
              render: singleLineAddress,
              customSort: sortByAddress('address'),
              customFilterAndSearch: (search: string, { address }: LocationData) =>
                addressIncludesSearch(address, search),
            },
            { field: 'country', title: 'Country', hidden: true },
            { field: 'icpNumber', title: 'ICP/NMI number', hidden: true },
            { field: 'gridExitPoint', title: 'GXP', hidden: true },
            { field: 'electricityDistributor', title: 'Electricity Distributor', hidden: true },
            { field: 'electricityRetailer', title: 'Electricity Retailer', hidden: true },
            {
              field: 'chargePointCount',
              title: 'Charge points',
            },
            { field: 'organisationName', title: 'Organisation' },
            {
              field: 'createdDate',
              title: 'Created date',
              type: 'date',
              hidden: true,
              render: ({ createdDate }) => dateFormat(createdDate),
            },
            {
              field: 'updatedDate',
              title: 'Updated date',
              type: 'date',
              hidden: true,
              render: ({ updatedDate }) => dateFormat(updatedDate),
            },
          ]}
          data={locations}
          onRowClick={navigateOnRowClick((rowData) => getLocationUrl(rowData), history)}
        />
      </Card>
    </Workspace>
  );
};
