import Box, { BoxProps } from '@material-ui/core/Box';
import Container, { ContainerProps } from '@material-ui/core/Container';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import deepmerge from 'deepmerge';
import * as React from 'react';
import { WizardStep } from './types';

interface WizardContextProps<S extends Record<string, unknown>> {
  state: S;
  step: number;
  setState: (state: Partial<S>) => void;
  nextStep: () => void;
  prevStep: () => void;
  isLoading?: boolean;
}

const obj = {};
const WizardContext = React.createContext<WizardContextProps<typeof obj>>({
  state: {},
  step: -1,
  setState: () => {},
  nextStep: () => {},
  prevStep: () => {},
  isLoading: false,
});

export function useWizard(): WizardContextProps<typeof obj> {
  return React.useContext<WizardContextProps<typeof obj>>(WizardContext);
}

export interface WizardProps<S extends Record<string, unknown>> {
  steps: WizardStep[];
  onComplete: (wizardState: S) => void;
  onExit: () => void;
  isLoading?: boolean;
  stepBoxProps?: BoxProps;
  stepContainerProps?: ContainerProps;
  hasStepper?: boolean;
}

const overwriteMerge = (_destinationArray: unknown[], sourceArray: unknown[]) => sourceArray;

function Wizard<T extends typeof obj>({
  steps,
  onComplete,
  onExit,
  isLoading,
  stepBoxProps,
  stepContainerProps,
  hasStepper,
}: WizardProps<T>): React.ReactElement {
  const [activeStep, setActiveStep] = React.useState(0);
  const [wizardState, setWizardState] = React.useState<T>({} as T);

  const updateWizardState = React.useCallback(
    (partialState: Partial<T>) => setWizardState(deepmerge(wizardState, partialState, { arrayMerge: overwriteMerge })),
    [wizardState],
  );

  const nextStep = React.useCallback(
    () => (activeStep + 1 > steps.length - 1 ? onComplete(wizardState) : setActiveStep((prev) => prev + 1)),
    [activeStep, steps, onComplete, wizardState],
  );

  const prevStep = React.useCallback(
    () => (activeStep - 1 < 0 ? onExit() : setActiveStep((prev) => prev - 1)),
    [activeStep, onExit],
  );

  const View = React.useMemo(() => steps[activeStep].view, [steps, activeStep]);

  return (
    <WizardContext.Provider
      value={{
        state: wizardState,
        step: activeStep,
        setState: updateWizardState,
        nextStep,
        prevStep,
        isLoading,
      }}
    >
      {hasStepper && (
        <Stepper activeStep={activeStep}>
          {steps.map(({ id, label, stepProps, stepLabelProps }) => (
            <Step key={id} {...stepProps}>
              <StepLabel {...stepLabelProps} StepIconProps={{ title: `${label} step` }}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <Box py={4} {...stepBoxProps}>
        <Container maxWidth="sm" {...stepContainerProps}>
          <View />
        </Container>
      </Box>
    </WizardContext.Provider>
  );
}

Wizard.displayName = 'Wizard';

export { Wizard };
