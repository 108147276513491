import { useSelector } from 'react-redux';
import type { MutateMethod } from 'restful-react';
import {
  CommandRemoteStartPathParams,
  RequestCommandRemoteStart,
  ResponseCommandDataTransfer,
  useCommandRemoteStart,
} from '../../../../../../../../app/ApiGen';
import { fullStateSelector } from '../../../../../../../../store/root';
import {
  CommandChangeAvailability,
  CommandRemoteStopTransaction,
  CommandUnlockConnector,
  PostChargePointCommandChangeAvailabilityPathParams,
  PostChargePointCommandStopTransactionPathParams,
  PostChargePointCommandUnlockConnectorPathParams,
  ResponsePostCommand,
  usePostChargePointCommandChangeAvailability,
  usePostChargePointCommandStopTransaction,
  usePostChargePointCommandUnlockConnector,
} from '../../../../../../../../utils/api';

interface ConnectorCommandsContextProps {
  anyLoading: boolean;
  startLoading: boolean;
  stopLoading: boolean;
  unlockLoading: boolean;
  availabilityLoading: boolean;
  error?: unknown;
  startMutate: MutateMethod<ResponseCommandDataTransfer, RequestCommandRemoteStart, void, CommandRemoteStartPathParams>;
  stopMutate: MutateMethod<
    ResponsePostCommand,
    CommandRemoteStopTransaction,
    void,
    PostChargePointCommandStopTransactionPathParams
  >;
  unlockMutate: MutateMethod<
    ResponsePostCommand,
    CommandUnlockConnector,
    void,
    PostChargePointCommandUnlockConnectorPathParams
  >;
  availabilityMutate: MutateMethod<
    ResponsePostCommand,
    CommandChangeAvailability,
    void,
    PostChargePointCommandChangeAvailabilityPathParams
  >;
}

export const useConnectorCommands: () => ConnectorCommandsContextProps = () => {
  const {
    organisation: { current: organisation },
    chargePoint: { current: chargePoint },
  } = useSelector(fullStateSelector);
  const organisationSlug = organisation?.slug ?? '';
  const chargePointId = chargePoint?.id ?? '';

  const {
    loading: startLoading,
    error: startError,
    mutate: startMutate,
  } = useCommandRemoteStart({
    id: chargePointId,
  });

  const {
    loading: stopLoading,
    error: stopError,
    mutate: stopMutate,
  } = usePostChargePointCommandStopTransaction({
    organisationSlug,
    chargePointId,
  });

  const {
    loading: unlockLoading,
    error: unlockError,
    mutate: unlockMutate,
  } = usePostChargePointCommandUnlockConnector({
    organisationSlug,
    chargePointId,
  });

  const {
    loading: availabilityLoading,
    error: availabilityError,
    mutate: availabilityMutate,
  } = usePostChargePointCommandChangeAvailability({
    organisationSlug,
    chargePointId,
  });

  const anyLoading = startLoading || stopLoading || unlockLoading || availabilityLoading;
  const error = startError ?? stopError ?? unlockError ?? availabilityError;

  return {
    anyLoading,
    startLoading,
    stopLoading,
    unlockLoading,
    availabilityLoading,
    error,
    startMutate,
    stopMutate,
    unlockMutate,
    availabilityMutate,
  };
};
