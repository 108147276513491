import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputComponentProps, ValueComponentProps } from '../../design-system';
import { getNestedHelperText } from '../../utils/formUtils';

export const CostValue: React.FC<ValueComponentProps<string | undefined>> = ({ value }) => {
  if (!value) {
    return <></>;
  }

  return <>${parseFloat(value)} Per kWh</>;
};

interface PriceInputProps extends NumberFormatProps {
  inputRef: (instance: NumberFormat<NumberFormatProps> | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}

const PriceInput: React.FC<PriceInputProps> = ({
  inputRef,
  onChange,
  'aria-invalid': ariaInvalid,
  autoFocus,
  className,
  disabled,
  id,
  required,
  type,
  value,
}) => (
  <NumberFormat
    getInputRef={inputRef}
    onValueChange={({ value: newValue }) => onChange({ target: { value: newValue } })}
    decimalScale={4}
    prefix="$"
    aria-invalid={ariaInvalid}
    autoFocus={autoFocus}
    className={className}
    disabled={disabled}
    id={id}
    required={required}
    value={value}
    type={type}
  />
);

export const CostInput: React.FC<InputComponentProps<string | undefined>> = ({
  name,
  value,
  helperText,
  onChange,
  disabled,
  error,
  onBlur,
}) => (
  <TextField
    disabled={disabled}
    aria-readonly="true"
    error={error}
    id={name}
    value={value}
    onBlur={onBlur}
    onChange={(e) => onChange(name)(e)}
    variant="outlined"
    margin="dense"
    style={{ margin: 0 }}
    fullWidth
    helperText={getNestedHelperText(helperText)}
    InputProps={{
      inputComponent: PriceInput as never,
      endAdornment: <InputAdornment position="end">Per kWh</InputAdornment>,
    }}
  />
);
