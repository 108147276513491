import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { JsonEditor } from 'json-edit-react';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ChargingConfiguration, useGetChargePoint, useUpdateChargePoint } from '../../../../../app/ApiGen';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { errorSnackbar } from '../../../../../components/ErrorSnackbar';
import { FormActions, List, ListItem, Loading, Section, Workspace } from '../../../../../design-system';
import { normalise } from '../../../../../utils/request';

const defaultChargingConfiguration: ChargingConfiguration = {
  currency: 'NZD' as const,
  tariffs: {
    import: { Flat: { type: 'TIME_OF_USE' as const, amount: '0.3' } },
    export: {},
  },
  periods: { day: [{ start: 0, behaviour: { type: 'Charge' }, importTariff: 'Flat' }] },
  repeats: 'daily',
};

export const ChargingConfigurationEditor: React.VFC = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const {
    loading: loadingChargePoint,
    data: chargePointData,
    error: chargePointError,
    refetch,
  } = useGetChargePoint({
    id,
  });
  const { error: updateError, mutate: mutateChargePoint, loading: updatingChargePoint } = useUpdateChargePoint({ id });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [chargingConfiguration, setChargingConfiguration] = React.useState<ChargingConfiguration | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (!chargePointData) {
      return;
    }
    setChargingConfiguration(chargePointData.data.attributes.chargingConfiguration ?? defaultChargingConfiguration);
  }, [chargePointData]);

  React.useEffect(() => {
    if (updateError) {
      errorSnackbar('Unable to edit charging configuration', closeSnackbar, enqueueSnackbar, updateError.message);
    }
  }, [updateError, closeSnackbar, enqueueSnackbar]);

  const onSave = React.useCallback(() => {
    mutateChargePoint({ data: { type: 'chargePoints', id, attributes: { chargingConfiguration } } })
      .then(() => {
        enqueueSnackbar('Charging configuration updated', { variant: 'success' });
      })
      .catch(() => {}); // Already handled by useUpdateChargePoint.
  }, [mutateChargePoint, chargingConfiguration, id, enqueueSnackbar]);

  const onSetToDefault = React.useCallback(() => {
    setChargingConfiguration(defaultChargingConfiguration);
  }, [setChargingConfiguration]);

  if (loadingChargePoint) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (chargePointError || !chargePointData) {
    return (
      <DataErrorHandler
        description="Unable to load charge point"
        error={chargePointError}
        refetch={async () => {
          await refetch();
        }}
      />
    );
  }

  const chargePoint = normalise(chargePointData);

  if (loadingChargePoint || !chargePoint) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (chargePointError) {
    return <DataErrorHandler description="Unable to load charge point" error={chargePointError} refetch={refetch} />;
  }

  return (
    <Workspace>
      <Section title="Charging configuration">
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <List>
                <ListItem>
                  {chargingConfiguration && (
                    <JsonEditor
                      data={chargingConfiguration}
                      setData={(jsonData) => {
                        setChargingConfiguration(jsonData as ChargingConfiguration);
                      }}
                    />
                  )}
                  <FormActions>
                    <Button
                      onClick={onSetToDefault}
                      disabled={loadingChargePoint || chargingConfiguration === defaultChargingConfiguration}
                      variant="contained"
                      color="default"
                    >
                      Set to all day schedule
                    </Button>
                    <Box ml={2} />
                    <Button
                      onClick={onSave}
                      disabled={loadingChargePoint}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {updatingChargePoint ? <CircularProgress size={26} color="inherit" /> : 'Save'}
                    </Button>
                  </FormActions>
                </ListItem>
              </List>
            </Card>
          </Grid>
        </Grid>
      </Section>
    </Workspace>
  );
};
