import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import EvStationIcon from '@material-ui/icons/EvStation';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { uid } from 'react-uid';
import { useGetLocation } from '../../../../../../../../app/ApiGen';
import { ChargePointAvatar } from '../../../../../../../../components/Avatar/ChargePoint';
import { getLocationAvatar } from '../../../../../../../../components/Avatar/Location';
import { Connectors } from '../../../../../../../../components/Connectors';
import { ErrorHandler } from '../../../../../../../../components/ErrorHandler';
import { Card, List, ListItem, Loading } from '../../../../../../../../design-system';
import { getAddressAsString } from '../../../../../../../../utils/locations/address';
import { getChargePoints } from '../../../../../../../../utils/locations/chargePoints';
import { normalise } from '../../../../../../../../utils/request';
import type { LocationInfoPos } from '../../types';
import { AvatarWrapper, Location as StyledLocation, Primary, Secondary, Text } from './styled';

interface LocationCardProps {
  onTitleClicked: () => void;
  name: string;
  address: string;
}

const LocationCard: React.FC<LocationCardProps> = ({ onTitleClicked, name, address, children }) => (
  <Card style={{ height: '100%', borderRadius: 0 }}>
    <ListItem onClick={onTitleClicked}>
      <StyledLocation>
        <AvatarWrapper>{getLocationAvatar()}</AvatarWrapper>
        <Text>
          <Primary title={name}>{name}</Primary>
          <Secondary title={address}>{address}</Secondary>
        </Text>
      </StyledLocation>
    </ListItem>
    <Divider />
    <Box height="calc(100% - 57px)" overflow="auto">
      {children}
    </Box>
  </Card>
);

export interface SingleLocationProps {
  location: LocationInfoPos;
  orgSlug: string;
}

export const SingleLocation: React.FC<SingleLocationProps> = ({ location, orgSlug }) => {
  const { id: locationId, name } = location;
  const address = getAddressAsString(location.address);

  const { loading, error, data } = useGetLocation({ locationId: location.id });

  const { push } = useHistory();

  const onLocationClicked = React.useCallback(
    (id: string) => push(`/organisations/${orgSlug}/locations/${id}`),
    [orgSlug, push],
  );

  const onChargePointClicked = React.useCallback(
    (id: string) => push(`/organisations/${orgSlug}/charge-points/${id}`),
    [orgSlug, push],
  );

  if (loading) {
    return (
      <LocationCard onTitleClicked={() => onLocationClicked(locationId)} name={name} address={address}>
        <Box mt={2}>
          <Loading size={32} />
        </Box>
      </LocationCard>
    );
  }

  if (error || !data) {
    return (
      <LocationCard onTitleClicked={() => onLocationClicked(locationId)} name={name} address={address}>
        <Box mt={2}>
          <ErrorHandler description="Unable to load location details" error={error} />
        </Box>
      </LocationCard>
    );
  }

  const chargePoints = getChargePoints(normalise(data));

  return (
    <LocationCard onTitleClicked={() => onLocationClicked(locationId)} name={name} address={address}>
      <List>
        {chargePoints.map((chargePoint, idx) => (
          <ListItem key={uid(chargePoint, idx)} onClick={() => onChargePointClicked(chargePoint.id)}>
            <StyledLocation>
              <AvatarWrapper>
                <ChargePointAvatar>
                  <EvStationIcon />
                </ChargePointAvatar>
              </AvatarWrapper>
              <Text>
                <Primary title={chargePoint.name}>{chargePoint.name}</Primary>
                <Connectors networkStatus={chargePoint.networkStatus} connectors={chargePoint.connectors} />
              </Text>
            </StyledLocation>
          </ListItem>
        ))}
      </List>
    </LocationCard>
  );
};
