import type { Reducer } from 'redux';
import * as Actions from './actions';
import { ChargePointAction, ChargePointState } from './types';

type Action = ReturnType<(typeof Actions)[keyof typeof Actions]>;

const initialState: ChargePointState = {
  loading: false,
  selectedItemId: undefined,
  error: undefined,
  current: undefined,
};

export const chargePointReducers: Reducer<ChargePointState, Action> = (state, action): ChargePointState => {
  switch (action.type) {
    case ChargePointAction.GET_CURRENT_CHARGE_POINT:
      return {
        ...(state ?? initialState),
        selectedItemId: action.payload.selectedItemId,
        loading: true,
        error: undefined,
      };
    case ChargePointAction.SET_CURRENT_CHARGE_POINT:
      return {
        ...(state ?? initialState),
        selectedItemId: action.payload.chargePoint.id,
        loading: false,
        error: undefined,
        current: action.payload.chargePoint,
      };
    case ChargePointAction.SET_CHARGE_POINT_ERROR:
      return {
        ...(state ?? initialState),
        selectedItemId: action.payload.selectedItemId,
        loading: false,
        error: action.payload.error,
      };
    case ChargePointAction.CLEAR_CURRENT_CHARGE_POINT:
      return {
        ...(state ?? initialState),
        selectedItemId: undefined,
        loading: false,
        error: undefined,
        current: undefined,
      };
    default:
      return { ...(state ?? initialState) };
  }
};
