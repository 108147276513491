import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import type { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete, { AutocompleteRenderInputParams, createFilterOptions } from '@material-ui/lab/Autocomplete';
import type { CreateFilterOptionsConfig } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import React from 'react';
import { uid } from 'react-uid';
import styled from 'styled-components';
import { InputComponentProps, ValueComponentProps } from '../../design-system';
import { basicStringify } from '../../utils/format';
import { getNestedHelperText } from '../../utils/formUtils';

export type AutocompleteContents<T = string> = ValueComponentProps<T> & {
  label?: T extends (infer E)[] ? (element: E) => string : string;
};

export interface AutocompleteInfo<T = string> extends AutocompleteContents<T> {
  disabled?: boolean;
  group?: string;
}

export const AutocompleteWrapper = styled('div')`
  width: 100%;

  .textfield-root > * {
    padding: ${(p) => p.theme.spacing(0, 1)};
  }
`;

interface AutocompleteInputProps<T = string> extends Omit<InputComponentProps<T>, 'checked'> {
  useNullAsDefaultValue?: boolean;
  suggestAddOption?: (options: AutocompleteInfo<T>[], inputValue: string) => AutocompleteInfo<T> | null;
  filterOptions?: CreateFilterOptionsConfig<AutocompleteInfo<T>>;
  options: AutocompleteInfo<T>[];
  useGrouping?: boolean;
  InputProps?: Partial<OutlinedInputProps>;
}

function getValue<T = string>({
  options,
  useNullAsDefaultValue,
  value,
}: Pick<AutocompleteInputProps<T>, 'options' | 'useNullAsDefaultValue' | 'value'>): AutocompleteInfo<T> | null {
  const valueInOptions = options.find((option) => option.value === value);
  if (valueInOptions) {
    return valueInOptions;
  }
  if (useNullAsDefaultValue) {
    return null;
  }
  return options[0];
}

export const renderAutocompleteInput: (
  disabled: boolean,
  error: boolean,
  helperText: string | Record<string, string> | Record<string, string>[] | undefined,
  InputProps?: OutlinedInputProps,
) => React.VFC<AutocompleteRenderInputParams> =
  (disabled, error, helperText, overrideInputProps) =>
  ({ id, fullWidth, size, InputLabelProps, InputProps, inputProps }: AutocompleteRenderInputParams) =>
    (
      <TextField
        className="textfield-root"
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        helperText={getNestedHelperText(helperText)}
        id={id}
        InputLabelProps={InputLabelProps}
        InputProps={{ ...InputProps, ...overrideInputProps }}
        // eslint-disable-next-line react/jsx-no-duplicate-props -- inputProps is different from InputProps; need both.
        inputProps={inputProps}
        margin="dense"
        size={size}
        style={{ margin: 0 }}
        variant="outlined"
      />
    );

export function AutocompleteValue<T>({ label, value }: AutocompleteContents<T>): React.ReactElement {
  if (Array.isArray(value) && typeof label === 'function') {
    return (
      <List dense>
        {value.map((v, idx) => (
          <ListItem style={{ paddingLeft: 0 }} key={uid(v, idx)}>
            {label(v)}
          </ListItem>
        ))}
      </List>
    );
  }

  const displayText = typeof label === 'string' ? label : basicStringify(value);

  return (
    <Typography color="textPrimary" variant="body2">
      {displayText}
    </Typography>
  );
}

export function AutocompleteInput<T = string>({
  disabled,
  error,
  filterOptions,
  helperText,
  InputProps,
  name,
  onBlur: formikBlurHandler,
  onChange: formikChangeHandler,
  options,
  suggestAddOption,
  useGrouping,
  useNullAsDefaultValue,
  value,
}: AutocompleteInputProps<T>): React.ReactElement {
  const filter = createFilterOptions(filterOptions);
  function getOptionLabel(option: AutocompleteInfo<T>): string {
    if (filterOptions?.stringify) {
      return filterOptions.stringify(option);
    }
    if (typeof option.label === 'function') {
      return option.label(option.value);
    }
    return option.label ?? basicStringify(option.value);
  }

  return (
    <AutocompleteWrapper>
      <Autocomplete
        disableClearable={!!value}
        disabled={disabled}
        filterOptions={(allOptions, state) => {
          const filtered = filter(allOptions, state);
          const { inputValue } = state;
          const suggested = suggestAddOption ? suggestAddOption(allOptions, inputValue) : null;
          if (suggested) {
            filtered.push(suggested);
          }
          return filtered;
        }}
        getOptionDisabled={(option) => !!option.disabled}
        getOptionLabel={(option) => getOptionLabel(option)}
        groupBy={useGrouping ? (option) => option.group ?? '' : undefined}
        handleHomeEndKeys
        onBlur={formikBlurHandler}
        onChange={(event, changedValue) => {
          formikChangeHandler(name)({ ...event, target: { ...event.target, value: changedValue?.value ?? null } });
        }}
        options={options}
        renderInput={renderAutocompleteInput(disabled, error, helperText, InputProps)}
        renderOption={(option) => option.label}
        selectOnFocus
        value={getValue({ useNullAsDefaultValue, options, value })}
      />
    </AutocompleteWrapper>
  );
}
