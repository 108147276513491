import { Clear } from '@material-ui/icons';
import { differenceBy } from 'lodash';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import React from 'react';
import { UserSummary } from '../../app/ApiGen';
import { errorSnackbar } from '../../components/ErrorSnackbar';
import { SelectableItem } from '../../components/SelectionList';
import { Normalised } from '../request';

type NormalisedUsers = Normalised<UserSummary> | Normalised<UserSummary>[];

interface RemoveUserProps<Data> {
  closeSnackbar: () => void;
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  refetch: () => Promise<Data | null>;
  removeUsers: (users: Normalised<UserSummary>[]) => Promise<void>;
  source: string;
}

interface RemoveUserAction {
  icon: () => JSX.Element;
  tooltip: string;
  onClick: (_: unknown, rowData: NormalisedUsers) => Promise<void>;
}

export const getSelectableUsers = (allUsers: UserSummary[], excludedUsers: UserSummary[]): SelectableItem[] => {
  const userOptions = differenceBy(allUsers, excludedUsers, 'id');
  return userOptions.map((u) => ({
    id: u.id,
    title: u.attributes.name ?? u.attributes.email,
    subtitle: u.attributes.email,
    color: u.attributes.color,
    image: u.attributes.avatar,
  }));
};

export function getDeleteUserAction<Data>({
  closeSnackbar,
  enqueueSnackbar,
  refetch,
  removeUsers,
  source,
}: RemoveUserProps<Data>): RemoveUserAction {
  return {
    icon: () => <Clear />,
    tooltip: 'Remove user',
    onClick: async (_: unknown, rowData: NormalisedUsers) => {
      const isMultipleUsers = Array.isArray(rowData);
      const usersToDelete: Normalised<UserSummary>[] = isMultipleUsers ? rowData : [rowData];
      try {
        await removeUsers(usersToDelete);
        const successMessage = isMultipleUsers
          ? `Users ${rowData.map((user) => user.email).join(', ')} were`
          : `User ${rowData.email} was`;
        enqueueSnackbar(`${successMessage} successfully removed from ${source}`, {
          variant: 'success',
        });
        await refetch();
      } catch (e) {
        errorSnackbar('Unable to remove user', closeSnackbar, enqueueSnackbar, e);
      }
    },
  };
}
