import type { Reducer } from 'redux';
import * as Actions from './actions';
import { OrganisationAction, OrganisationState } from './types';

type Action = ReturnType<(typeof Actions)[keyof typeof Actions]>;

const initialState: OrganisationState = {
  loading: false,
  current: undefined,
};

export const organisationReducers: Reducer<OrganisationState, Action> = (state, action): OrganisationState => {
  switch (action.type) {
    case OrganisationAction.SET_CURRENT_ORGANISATION:
      return {
        ...(state ?? initialState),
        loading: false,
        current: action.payload.current,
      };
    case OrganisationAction.CHANGE_CURRENT_ORGANISATION:
      return {
        ...(state ?? initialState),
        loading: true,
        current: undefined,
      };
    case OrganisationAction.CLEAR_CURRENT_ORGANISATION:
      return {
        ...(state ?? initialState),
        loading: false,
        current: undefined,
      };
    default:
      return state ?? initialState;
  }
};
