import type { Reducer } from 'redux';
import * as Actions from './actions';
import { LoadBalancingGroupAction, LoadBalancingGroupState } from './types';

type Action = ReturnType<(typeof Actions)[keyof typeof Actions]>;

const initialState: LoadBalancingGroupState = {
  loading: false,
  error: undefined,
  current: undefined,
  selectedItemId: undefined,
};

export const loadBalancingGroupReducers: Reducer<LoadBalancingGroupState, Action> = (
  state,
  action,
): LoadBalancingGroupState => {
  switch (action.type) {
    case LoadBalancingGroupAction.GET_CURRENT_LOAD_BALANCING_GROUP:
      return {
        ...(state ?? initialState),
        loading: true,
        error: undefined,
        selectedItemId: action.payload.selectedItemId,
      };
    case LoadBalancingGroupAction.SET_CURRENT_LOAD_BALANCING_GROUP:
      return {
        ...(state ?? initialState),
        selectedItemId: action.payload.loadBalancingGroup.id,
        loading: false,
        error: undefined,
        current: action.payload.loadBalancingGroup,
      };
    case LoadBalancingGroupAction.SET_LOAD_BALANCING_GROUP_ERROR:
      return {
        selectedItemId: action.payload.selectedItemId,
        ...(state ?? initialState),
        loading: false,
        error: action.payload.error,
      };
    case LoadBalancingGroupAction.CLEAR_CURRENT_LOAD_BALANCING_GROUP:
      return {
        ...(state ?? initialState),
        selectedItemId: undefined,
        loading: false,
        error: undefined,
        current: undefined,
      };
    default:
      return state ?? initialState;
  }
};
