import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ClearIcon from '@material-ui/icons/Clear';
import Autocomplete from '@material-ui/lab/Autocomplete';
import type { FieldInputProps } from 'formik';
import React from 'react';
import type { ChargePointSummary } from '../../app/ApiGen';
import { List, ListHeader, ListItem } from '../../design-system';
import type { Normalised } from '../../utils/request';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// TODO: This type won't be necessary when getOrganisationChargePoints returns normalisable ChargePoint DTOs
export type SelectableChargePoint = Pick<Normalised<ChargePointSummary>, 'serial' | 'name' | 'id'>;

interface ChargePointsSelectProps {
  chargePoints: SelectableChargePoint[];
  fieldInputProps?: Partial<FieldInputProps<string>>;
  textFieldProps?: TextFieldProps;
  preSelectedChargePoints: SelectableChargePoint[];
  onClear?: () => void;
  onChangeSelection?: (selected: SelectableChargePoint[]) => void;
}

function chargePointToString(chargePoint: SelectableChargePoint): string {
  return chargePoint.serial ? `Name: ${chargePoint.name}, Serial: ${chargePoint.serial}` : `Name: ${chargePoint.name}`;
}

function sortBySerial(chargePoint1: SelectableChargePoint, chargePoint2: SelectableChargePoint): number {
  const serial1 = chargePoint1.serial ?? '';
  const serial2 = chargePoint2.serial ?? '';
  if (serial1 === serial2) {
    const name1 = chargePoint1.name ?? '';
    const name2 = chargePoint2.name ?? '';
    return name1 < name2 ? -1 : 1;
  }
  return serial1 < serial2 ? -1 : 1;
}

const ChargePointMultiSelect: React.FC<ChargePointsSelectProps> = (props: ChargePointsSelectProps) => {
  const { chargePoints, fieldInputProps, textFieldProps, onClear, onChangeSelection, preSelectedChargePoints } = props;
  const [selectedChargePoints, setSelectedChargePoints] =
    React.useState<SelectableChargePoint[]>(preSelectedChargePoints);

  function unselectChargePoint(chargePoint: SelectableChargePoint): void {
    const filteredChargePoints = selectedChargePoints.filter((selected) => selected.id !== chargePoint.id);
    setSelectedChargePoints(filteredChargePoints);

    if (onChangeSelection) {
      onChangeSelection(filteredChargePoints);
    }
  }

  const renderChargePointItem = (chargePoint: SelectableChargePoint) => (
    <ListItem key={chargePoint.id}>
      <Grid container>
        <Grid item xs={5}>
          {chargePoint.name}
        </Grid>
        <Grid item xs={6}>
          {chargePoint.serial}
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="remove charge point" size="small" onClick={() => unselectChargePoint(chargePoint)}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete<SelectableChargePoint, true, true>
          multiple
          disableClearable
          fullWidth
          selectOnFocus
          handleHomeEndKeys
          disableCloseOnSelect
          options={chargePoints}
          value={selectedChargePoints}
          getOptionLabel={chargePointToString}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox size="small" color="primary" icon={icon} checkedIcon={checkedIcon} checked={selected} />
              {chargePointToString(option)}
            </>
          )}
          renderInput={(inputProps) => (
            <TextField
              {...inputProps}
              {...fieldInputProps}
              {...textFieldProps}
              label="Select charge points"
              margin="dense"
              variant="outlined"
              className="textfield-root"
            />
          )}
          renderTags={() => null}
          onChange={(event, value, reason) => {
            setSelectedChargePoints(value);

            if (reason === 'clear' && onClear) {
              onClear();
            }
            if ((reason === 'select-option' || reason === 'remove-option') && onChangeSelection) {
              onChangeSelection(value);
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {selectedChargePoints.length > 0 && (
          <List variant="bordered">
            <ListHeader>
              <Grid container>
                <Grid item xs={5}>
                  Name
                </Grid>
                <Grid item xs={5}>
                  Serial
                </Grid>
                <Grid item xs={1}>
                  Remove
                </Grid>
              </Grid>
            </ListHeader>
            {selectedChargePoints.sort(sortBySerial).map(renderChargePointItem)}
          </List>
        )}
      </Grid>
    </Grid>
  );
};

export default ChargePointMultiSelect;
