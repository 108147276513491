import { cloneDeep } from 'lodash';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ColumnFiltersToggle, RefreshButton } from '../../../../../../../components/DataTable/Toolbar';
import { DateRangePicker } from '../../../../../../../components/DateRange';
import {
  Card,
  ColumnChanger,
  ColumnChangerProps,
  DataTable,
  SearchField,
  SearchFieldProps,
  Section,
} from '../../../../../../../design-system';
import { ChargePointLog } from '../../../../../../../utils/api';
import { DateRangeProps } from '../../../../../../../utils/dateRange';
import { includesSearch } from '../../../../../../../utils/search';
import { sortByDate } from '../../../../../../../utils/sortBy';
import { renderLogData } from '../util';
import { renderDate, renderMessageId } from './tableUtils';

interface ChargePointLogsTableProps<Data> {
  loading: boolean;
  refetch: () => Promise<Data | null>;
  chargePointLogs: ChargePointLog[];
  title?: string;
  description?: string;
}

export function ChargePointLogsTable<Data>({
  dateProps,
  tableProps,
}: {
  tableProps: ChargePointLogsTableProps<Data>;
  dateProps: DateRangeProps;
}) {
  const [filtering, setFiltering] = React.useState(false);

  const { chargePointLogs, description, loading, refetch, title } = tableProps;

  const {
    dateRange: { fromDate, toDate },
    setDateRange,
  } = dateProps;

  return (
    <Section
      title={title ?? 'Charge point logs'}
      description={
        description ??
        'View messages sent and received by the system from the charge point. ' +
          'Note that if you select a very wide range, the request is more likely to time out.'
      }
    >
      <Card>
        <DataTable<ChargePointLog>
          isLoading={loading}
          options={{ columnsButton: true, filtering }}
          toolbarProps={{
            search: (props: SearchFieldProps) => (
              <span style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ paddingBottom: 8 }}>
                  <DateRangePicker fromDate={fromDate} toDate={toDate} onApply={setDateRange} />
                </span>
                <SearchField
                  search={props.search}
                  searchText={props.searchText}
                  onSearchChanged={props.onSearchChanged}
                  placeholder="Search logs..."
                />
              </span>
            ),
            actions: (props: PropsWithChildren<ColumnChangerProps>) => (
              <>
                <ColumnChanger
                  columnsButton={props.columnsButton}
                  columns={props.columns}
                  icons={props.icons}
                  onColumnsChanged={props.onColumnsChanged}
                />
                <ColumnFiltersToggle filtering={filtering} setFiltering={setFiltering} />
                <RefreshButton refetch={refetch} />
              </>
            ),
          }}
          columns={[
            {
              title: 'Server timestamp',
              field: 'serverEventDate',
              render: ({ serverEventDate }: ChargePointLog) => renderDate(serverEventDate),
              customSort: sortByDate('serverEventDate'),
              defaultSort: 'desc',
              filtering: false,
            },
            {
              title: 'Charge point timestamp',
              field: 'chargePointEventDate',
              render: ({ chargePointEventDate }: ChargePointLog) => renderDate(chargePointEventDate),
              customSort: sortByDate('chargePointEventDate'),
              filtering: false,
            },
            {
              title: 'Message ID',
              field: 'messageId',
              render: ({ messageId }: ChargePointLog) => renderMessageId(messageId),
              filtering: false,
            },
            {
              title: 'Event',
              field: 'event',
            },
            {
              title: 'Data',
              field: 'data',
              hidden: true,
              sorting: false,
              filtering: true,
              searchable: true,
              render: renderLogData,
              customFilterAndSearch: (filter: string, { data }: ChargePointLog) =>
                includesSearch(JSON.stringify(data), filter),
            },
          ]}
          data={cloneDeep(chargePointLogs)}
          detailPanel={renderLogData}
        />
      </Card>
    </Section>
  );
}
