import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import {
  ChargePointInfoWithLocation,
  LegacyAddressDTO,
  useLegacyGetOrganisationChargePoints,
} from '../../../../../app/ApiGen';
import { singleLineAddress } from '../../../../../components/Address';
import { Connectors } from '../../../../../components/Connectors';
import { ColumnFiltersToggle, getToolbarSearch, RefreshButton } from '../../../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { TableLink } from '../../../../../components/Link';
import { NoChargePoints } from '../../../../../components/NoContentPlaceholder/NoChargePoints';
import { ColumnChanger, ColumnChangerProps, DataTable, Workspace } from '../../../../../design-system';
import { fullStateSelector } from '../../../../../store/root';
import { getChargePointConnectivityStatus } from '../../../../../utils/chargePoints/getChargePointConnectivityStatus';
import {
  chargePointIdColumn,
  connectivityStatusColumn,
  firmwareVersionColumn,
  modelColumn,
  ocppChargePointIdColumn,
  referenceIdColumn,
  serialColumn,
  vendorColumn,
} from '../../../../../utils/chargePoints/table/columns';
import { navigateOnRowClick } from '../../../../../utils/dataTable/rowHelpers';
import { addressIncludesSearch, connectorsIncludeSearch } from '../../../../../utils/search';
import { sortByAddress, sortByConnectors, sortByLocale } from '../../../../../utils/sortBy';

type TableChargePoint = ChargePointInfoWithLocation & {
  address: LegacyAddressDTO;
  connectivityStatus: string;
  firmware: string;
  locationName: string;
  model: string;
  referenceId: string;
  vendor: string;
};

const getTableChargePoints = (chargePoints: ChargePointInfoWithLocation[]): TableChargePoint[] =>
  chargePoints.map((chargePoint) => {
    const { details, location, metadata, networkStatus, networkStatusUpdatedDate } = chargePoint;
    return {
      ...chargePoint,
      address: location.address,
      connectivityStatus: getChargePointConnectivityStatus(networkStatus, networkStatusUpdatedDate),
      firmware: details.firmware ?? '',
      locationName: location.name,
      model: details.model ?? '',
      referenceId: metadata?.referenceId ?? '',
      vendor: details.vendor ?? '',
    };
  });

const NoChargePointsView: React.FC<{ orgSlug: string }> = ({ orgSlug }) => {
  const { isDriver } = useSelector(fullStateSelector);
  return (
    <Workspace maxWidth="xl">
      <Card>
        <Grid style={{ padding: 30 }} container direction="column" justify="center" alignItems="center">
          <NoChargePoints orgSlug={orgSlug} isDriver={isDriver} />
        </Grid>
      </Card>
    </Workspace>
  );
};

const AddChargePointButton: React.FC<{ orgSlug: string }> = ({ orgSlug }) => {
  const { isAdmin, isEvnexAdmin } = useSelector(fullStateSelector);

  if (isAdmin || isEvnexAdmin) {
    return (
      <Button component={Link} to={`/organisations/${orgSlug}/create/charge-point`} color="primary" variant="contained">
        Add charge point
      </Button>
    );
  }

  return <></>;
};

export const ChargePointsListView: React.FC = () => {
  const { url, params } = useRouteMatch<{ slug: string }>();
  const history = useHistory();
  const [filtering, setFiltering] = React.useState(false);
  const { slug: orgSlug } = params;
  const {
    loading,
    error,
    data: chargePointsData,
    refetch,
  } = useLegacyGetOrganisationChargePoints({
    orgSlug,
  });

  if (error) {
    return <DataErrorHandler description="Unable to load charge points" error={error} refetch={refetch} />;
  }

  const chargePoints = chargePointsData ? getTableChargePoints(chargePointsData.data.items) : [];

  if (!loading && chargePoints.length === 0) {
    return <NoChargePointsView orgSlug={orgSlug} />;
  }

  return (
    <Workspace maxWidth="xl">
      <Card>
        <DataTable<TableChargePoint>
          isLoading={loading}
          options={{ columnsButton: true, filtering }}
          toolbarProps={{
            ...getToolbarSearch('charge points'),
            actions: (props: ColumnChangerProps) => (
              <>
                <ColumnChanger
                  columnsButton={props.columnsButton}
                  columns={props.columns}
                  icons={props.icons}
                  onColumnsChanged={props.onColumnsChanged}
                />
                <ColumnFiltersToggle filtering={filtering} setFiltering={setFiltering} />
                <RefreshButton refetch={refetch} />
                <AddChargePointButton orgSlug={orgSlug} />
              </>
            ),
          }}
          columns={[
            {
              title: 'Name',
              field: 'name',
              defaultSort: 'asc',
              customSort: sortByLocale('name'),
              render: ({ id, name }) => <TableLink to={`${url}/${id}`}>{name}</TableLink>,
            },
            {
              title: 'Location name',
              field: 'locationName',
              customSort: sortByLocale('location.name'),
            },
            {
              title: 'Location address',
              field: 'address',
              hidden: true,
              customSort: sortByAddress('location.address'),
              render: ({ location }) => singleLineAddress(location),
              customFilterAndSearch: (search: string, { location: { address } }) =>
                addressIncludesSearch(address, search),
            },
            connectivityStatusColumn<TableChargePoint>(),
            referenceIdColumn<TableChargePoint>(),
            ocppChargePointIdColumn<TableChargePoint>(),
            chargePointIdColumn<TableChargePoint>(),
            serialColumn<TableChargePoint>(),
            modelColumn<TableChargePoint>(),
            vendorColumn<TableChargePoint>(),
            firmwareVersionColumn<TableChargePoint>(),
            {
              title: 'Connectors',
              field: 'connectors',
              render: ({ connectors, networkStatus }) => (
                <Connectors networkStatus={networkStatus} connectors={connectors} />
              ),
              customFilterAndSearch: connectorsIncludeSearch,
              customSort: sortByConnectors,
            },
          ]}
          data={chargePoints}
          onRowClick={navigateOnRowClick((rowData) => `${url}/${rowData.id}`, history)}
        />
      </Card>
    </Workspace>
  );
};
