import React from 'react';
import { useListUsers } from '../../../../../app/ApiGen';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { WizardStep } from '../../../../../components/WizardStep';
import { Loading, OnSubmit, useWizard } from '../../../../../design-system';
import {
  evnexJobDetailsSchema,
  EvnexJobFormDetails,
  evnexJobFormFields,
} from '../../../../../utils/jobs/CreateEvnexJob';
import { getInstallersDetails } from '../../../../../utils/jobs/installer';
import type { JobWizardState } from '../types';

export const JobDetailsStep: React.FC = () => {
  const { state: untypedState, setState: untypedSetState, prevStep, nextStep, step } = useWizard();
  const state = untypedState as JobWizardState;
  const setState = untypedSetState as (state: Partial<JobWizardState>) => void;
  const initialValues: Required<EvnexJobFormDetails> = {
    assignedInstallers: [],
    referenceId: '',
    contactPhoneNumber: '',
    powerSensorCount: 1,
  };

  const onCancel = React.useCallback<OnSubmit<EvnexJobFormDetails>>(
    (values) => {
      setState(values);
      prevStep();
    },
    [setState, prevStep],
  );

  const onSubmit = React.useCallback<OnSubmit<EvnexJobFormDetails>>(
    (job: EvnexJobFormDetails) => {
      setState(job);
      nextStep();
    },
    [setState, nextStep],
  );

  const {
    data: installers,
    refetch,
    error,
    loading,
  } = useListUsers({
    queryParams: { type: 'installer' },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !installers) {
    return <DataErrorHandler description="Unable to load installers" error={error} refetch={refetch} type="embedded" />;
  }

  const formFields = evnexJobFormFields({ installerOptions: getInstallersDetails(installers) });

  return (
    <WizardStep<EvnexJobFormDetails>
      fields={Object.values(formFields)}
      initialValues={{ ...initialValues, ...state }}
      name="Job details"
      onCancel={onCancel}
      onSubmit={onSubmit}
      step={step}
      subtitle="Details about the home installation job"
      validationSchema={evnexJobDetailsSchema}
    />
  );
};
