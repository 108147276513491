import * as React from 'react';
import { useWizard } from '../../../../../../design-system';
import { ConfirmationStepEvnex } from './ConfirmationStepEvnex';
import { ConfirmationStepOther } from './ConfirmationStepOther';

export const ConfirmationStep: React.FC = () => {
  const wizard = useWizard();
  const { state } = wizard as unknown as { state: { vendor: string } };

  switch (state.vendor) {
    case 'evnex':
      return <ConfirmationStepEvnex />;
    default:
      return <ConfirmationStepOther />;
  }
};
