import { useSnackbar } from 'notistack';
import React from 'react';
import {
  Job,
  JobStatus,
  RequestUpdateJob,
  ResponseGetJob,
  useAssignInstallers,
  useGetAttachments,
  useListUsers,
  useUnassignInstallers,
  useUpdateJob,
} from '../../../../app/ApiGen';
import { DataErrorHandler } from '../../../../components/ErrorHandler';
import { errorSnackbar } from '../../../../components/ErrorSnackbar';
import { Card, Form, FormLoading, FormReadonly, OnSubmit, Section } from '../../../../design-system';
import type { FormFields } from '../../../../utils/formUtils';
import {
  attachedFilesField,
  EvnexInstallationDetails,
  evnexInstallationDetailsFields,
  evnexInstallationDetailsValidationSchema,
  JobAttachments,
  toInstallationDetailsFields,
  toJobAttachmentsField,
} from '../../../../utils/jobs/FieldDefinitions';
import { getInstallersDetails } from '../../../../utils/jobs/installer';
import { Normalised } from '../../../../utils/request';
import { getUpdatedInstallers } from './installersUtil';

interface JobDetailsSectionProps {
  job: Normalised<Job>;
  refetchJob: () => Promise<ResponseGetJob | null>;
}

interface FormEditContext {
  formFields: FormFields<EvnexInstallationDetails>;
  initialValues: Required<EvnexInstallationDetails>;
  job: Normalised<Job>;
  refetchJob: () => Promise<ResponseGetJob | null>;
}

export function mapInstallationDetailsToRequestUpdateJob(args: {
  jobId: string;
  previous: Normalised<Job>['details'];
  update: EvnexInstallationDetails;
  jobStatus: JobStatus;
}): RequestUpdateJob {
  const { jobId, previous, update } = args;
  const { concealedCableLength, duration, isThirdParty, notes, referenceId, surfaceCableLength } = update;

  const attributes: RequestUpdateJob['data']['attributes'] = {
    details: {
      ...previous,
      concealedCableLength: concealedCableLength ?? undefined,
      surfaceCableLength: surfaceCableLength ?? undefined,
    },
    duration: duration ?? undefined,
    isThirdParty,
    notes,
    referenceId: referenceId?.length === 0 ? undefined : referenceId ?? undefined,
  };

  return { data: { id: jobId, type: 'jobs', attributes } };
}

const JobDetailsForm: React.VFC<FormEditContext> = ({
  formFields,
  job,
  refetchJob,
  initialValues,
}: FormEditContext) => {
  const { id: jobId, details, status: jobStatus } = job;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { mutate: assignInstallers } = useAssignInstallers({ jobId });
  const { mutate: unassignInstallers } = useUnassignInstallers({ jobId });
  const { mutate: updateJob } = useUpdateJob({ jobId });
  const [isEditing, setIsEditing] = React.useState(false);

  const onSubmit = React.useCallback<OnSubmit<EvnexInstallationDetails>>(
    async (installationDetails, { setSubmitting }) => {
      try {
        await updateJob(
          mapInstallationDetailsToRequestUpdateJob({
            jobId,
            jobStatus,
            previous: details,
            update: installationDetails,
          }),
        );
        const { installersToAssign, installersToUnassign } = getUpdatedInstallers({
          originalInstallers: initialValues.assignedInstallers,
          selectedInstallers: installationDetails.assignedInstallers,
        });
        if (installersToAssign.length) {
          await assignInstallers({
            data: installersToAssign.map((installer) => ({ id: installer.id, type: 'users' })),
          });
        }
        if (installersToUnassign.length) {
          await unassignInstallers({
            data: installersToUnassign.map((installer) => ({ id: installer.id, type: 'users' })),
          });
        }

        enqueueSnackbar('Job updated', { variant: 'success' });
        setSubmitting(false);
        setIsEditing(false);
        await refetchJob();
      } catch (err) {
        setSubmitting(false);
        errorSnackbar('Unable to edit job', closeSnackbar, enqueueSnackbar, err);
      }
    },
    [
      assignInstallers,
      closeSnackbar,
      details,
      enqueueSnackbar,
      initialValues.assignedInstallers,
      jobId,
      jobStatus,
      refetchJob,
      unassignInstallers,
      updateJob,
    ],
  );

  return (
    <Form<EvnexInstallationDetails>
      fields={Object.values(formFields)}
      initialValues={initialValues}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      onSubmit={onSubmit}
      validationSchema={evnexInstallationDetailsValidationSchema}
    />
  );
};

export const InstallationDetailsSection: React.VFC<JobDetailsSectionProps> = ({
  job,
  refetchJob,
}: JobDetailsSectionProps) => {
  const {
    data: allInstallers,
    refetch: refetchAllInstallers,
    error: installersError,
    loading: installersLoading,
  } = useListUsers({
    queryParams: { type: 'installer' },
  });
  const {
    data: attachments,
    loading: attachmentsLoading,
    error: attachmentsError,
    refetch: refetchAttachments,
  } = useGetAttachments({
    jobId: job.id,
  });

  const placeholderFields = Object.values(evnexInstallationDetailsFields([]));

  if (installersLoading || attachmentsLoading) {
    return (
      <Section data-testid="skeleton">
        <Card>
          <FormLoading fields={placeholderFields} />
        </Card>
      </Section>
    );
  }

  const attachmentsAlert =
    attachmentsError || !attachments ? (
      <DataErrorHandler
        error={attachmentsError}
        description="Unable to load attached files"
        refetch={refetchAttachments}
        severity="info"
      />
    ) : null;

  const initialDetailsValues = toInstallationDetailsFields(job);
  const initialAttachmentValues = { attachments: toJobAttachmentsField(attachments?.data) };

  if (installersError || !allInstallers) {
    return (
      <Section>
        <Card title="Installation">
          <DataErrorHandler
            severity="info"
            description="Unable to load installers"
            error={installersError}
            refetch={refetchAllInstallers}
          />
          <FormReadonly<EvnexInstallationDetails> fields={placeholderFields} initialValues={initialDetailsValues} />
          {attachmentsAlert}
          <FormReadonly<JobAttachments> fields={[attachedFilesField]} initialValues={initialAttachmentValues} />
        </Card>
      </Section>
    );
  }

  return (
    <Section>
      <Card title="Installation">
        <JobDetailsForm
          formFields={evnexInstallationDetailsFields(getInstallersDetails(allInstallers))}
          initialValues={initialDetailsValues}
          job={job}
          refetchJob={refetchJob}
        />
        {attachmentsAlert}
        <FormReadonly<JobAttachments> fields={[attachedFilesField]} initialValues={initialAttachmentValues} />
      </Card>
    </Section>
  );
};
