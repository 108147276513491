import type { Reducer } from 'redux';
import * as Actions from './actions';
import { AuthenticatedUserAction, AuthenticatedUserState } from './types';

type Action = ReturnType<(typeof Actions)[keyof typeof Actions]>;

const initialState: AuthenticatedUserState = {
  loading: false,
  mfaRequired: false,
  error: undefined,
  current: undefined,
};

export const authenticatedUserReducers: Reducer<AuthenticatedUserState, Action> = (
  state,
  action,
): AuthenticatedUserState => {
  switch (action.type) {
    case AuthenticatedUserAction.GET_AUTHENTICATED_USER:
      return {
        ...(state ?? initialState),
        loading: true,
        error: undefined,
      };
    case AuthenticatedUserAction.SET_AUTHENTICATED_USER:
      return {
        ...(state ?? initialState),
        loading: false,
        error: undefined,
        current: action.payload.user,
      };
    case AuthenticatedUserAction.SET_AUTHENTICATED_USER_ERROR:
      return {
        ...(state ?? initialState),
        loading: false,
        error: action.payload.error,
      };
    case AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER:
      return {
        ...(state ?? initialState),
        loading: true,
        error: undefined,
      };
    case AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER_DONE:
      return {
        ...(state ?? initialState),
        loading: false,
        error: undefined,
        current: undefined,
      };
    case AuthenticatedUserAction.SET_AUTHENTICATED_USER_NEEDS_MFA:
      return {
        ...(state ?? initialState),
        mfaRequired: true,
      };
    case AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER_NEEDS_MFA:
      return {
        ...(state ?? initialState),
        mfaRequired: false,
      };
    default:
      return state ?? initialState;
  }
};
