import Card from '@material-ui/core/Card';
import moment, { Moment } from 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  ChargePointInfoWithLocation,
  OrganisationSummary,
  useLegacyGetOrganisationChargePoints,
} from '../../../app/ApiGen';
import { ColumnFiltersToggle, getToolbarSearch, RefreshButton } from '../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../components/ErrorHandler';
import { ExportCsv, ExportProps } from '../../../components/ExportCsv';
import { TableLink } from '../../../components/Link';
import { ColumnChanger, ColumnChangerProps, DataTable } from '../../../design-system';
import { navigateOnRowClick } from '../../../utils/dataTable/rowHelpers';
import type { Normalised } from '../../../utils/request';
import { sortByLocale } from '../../../utils/sortBy';

type TableChargePoint = ChargePointInfoWithLocation & {
  vendor: string;
  activeThisMonth: string;
};

export const activeSinceFirstOfPreviousMonth = (
  chargePoint: ChargePointInfoWithLocation,
  pointInTime: Moment,
): string => {
  if (typeof chargePoint.networkStatusUpdatedDate === 'undefined') {
    return 'No';
  }
  return moment(chargePoint.networkStatusUpdatedDate).isSameOrAfter(pointInTime.startOf('month').subtract(1, 'month'))
    ? 'Yes'
    : 'No';
};

const ChargePointListView: React.FC<{ organisation: Normalised<OrganisationSummary> }> = ({ organisation }) => {
  const history = useHistory();
  const [filtering, setFiltering] = React.useState(false);
  const {
    loading,
    error,
    data: chargePointsData,
    refetch,
  } = useLegacyGetOrganisationChargePoints({
    orgSlug: organisation.slug,
  });

  if (error) {
    return <DataErrorHandler description="Unable to load charge points" error={error} refetch={refetch} />;
  }
  const chargePoints = chargePointsData ? chargePointsData.data.items : [];
  const now = moment();
  const tableChargePoints = chargePoints.map((chargePoint) => ({
    ...chargePoint,
    vendor: chargePoint.details.vendor ?? 'Unknown',
    activeThisMonth: activeSinceFirstOfPreviousMonth(chargePoint, now),
  }));

  return (
    <Card>
      <DataTable<TableChargePoint>
        isLoading={loading}
        options={{
          columnsButton: true,
          filtering,
        }}
        toolbarProps={{
          ...getToolbarSearch('charge points'),
          actions: (props: ColumnChangerProps & ExportProps<TableChargePoint>) => (
            <>
              <ColumnChanger
                columnsButton={props.columnsButton}
                columns={props.columns}
                icons={props.icons}
                onColumnsChanged={props.onColumnsChanged}
              />
              <ColumnFiltersToggle filtering={filtering} setFiltering={setFiltering} />
              <RefreshButton refetch={refetch} />
              <ExportCsv
                columns={props.columns}
                data={props.data}
                exportFileName={`${organisation.name}-charge-points`}
                getFieldValue={props.getFieldValue}
                icons={props.icons}
              />
            </>
          ),
        }}
        columns={[
          {
            title: 'Name',
            field: 'name',
            defaultSort: 'asc',
            customSort: sortByLocale('name'),
            render: ({ id, name }: Pick<TableChargePoint, 'id' | 'name'>) => (
              <TableLink to={`/organisations/${organisation.slug}/charge-points/${id}`}>{name}</TableLink>
            ),
          },
          {
            title: 'Reference ID',
            field: 'metadata.referenceId',
          },
          {
            title: 'Serial',
            field: 'serial',
          },
          {
            title: 'Vendor',
            field: 'vendor',
          },
          {
            title: 'Active this month',
            field: 'activeThisMonth',
          },
        ]}
        data={tableChargePoints}
        onRowClick={navigateOnRowClick((row) => `/organisations/${organisation.slug}/charge-points/${row.id}`, history)}
      />
    </Card>
  );
};

export default ChargePointListView;
