import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useLegacyGetOrganisationChargePoints, useUpdateLoadBalancingGroup } from '../../../../../../../app/ApiGen';
import ChargePointMultiSelect, {
  SelectableChargePoint,
} from '../../../../../../../components/CustomFields/ChargePointMultiSelect';
import { DataErrorHandler } from '../../../../../../../components/ErrorHandler';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { Card, Field, FormActions, List, ListItem, Loading, OnSubmit } from '../../../../../../../design-system';
import type { SubState } from '../../../../../../../store/types';
import { numericSchemaParam } from '../../../../../../../utils/formUtils';
import {
  CreateLoadBalancingGroup,
  validationSchema,
} from '../../../../../../../utils/loadBalancingGroups/FieldDefinitions';

const LoadBalancingGroupDetails: React.FC<{
  loadBalancingGroup: SubState<'loadBalancingGroup'>;
  orgChargePoints: SelectableChargePoint[];
  refetchLoadBalancingGroup: () => void;
}> = ({ loadBalancingGroup, orgChargePoints, refetchLoadBalancingGroup }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { mutate: updateLoadBalancingGroup } = useUpdateLoadBalancingGroup({
    loadBalancingGroupId: loadBalancingGroup.id,
  });

  const currentChargePointsIds = loadBalancingGroup.chargePoints.map((chargePoint) => chargePoint.id);
  const preSelectedChargePoints = orgChargePoints.filter((chargePoint) =>
    currentChargePointsIds.includes(chargePoint.id),
  );

  const initialValues: CreateLoadBalancingGroup = {
    name: loadBalancingGroup.name,
    enabled: loadBalancingGroup.enabled,
    maximumCurrent: loadBalancingGroup.phases[0].maximumCurrent,
    chargePoints: preSelectedChargePoints,
  };

  const onSubmit = React.useCallback<OnSubmit<CreateLoadBalancingGroup>>(
    async ({ name, enabled, maximumCurrent, chargePoints }, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await updateLoadBalancingGroup({
          data: {
            id: loadBalancingGroup.id,
            type: 'loadBalancingGroups',
            attributes: { name, enabled, phases: [{ maximumCurrent }] },
            relationships: {
              chargePoints: { data: (chargePoints ?? []).map((c) => ({ type: 'chargePoints', id: c.id })) },
            },
          },
        });
        enqueueSnackbar('Load balancing group updated', { variant: 'success' });
        refetchLoadBalancingGroup();
      } catch (submitError) {
        errorSnackbar('Unable to create load balancing group', closeSnackbar, enqueueSnackbar, submitError);
      } finally {
        setSubmitting(false);
      }
    },
    [closeSnackbar, enqueueSnackbar, loadBalancingGroup.id, refetchLoadBalancingGroup, updateLoadBalancingGroup],
  );

  return (
    <Card title="Details">
      <Formik<CreateLoadBalancingGroup>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange
      >
        {({ isSubmitting, values, touched, handleChange, setFieldValue, errors }) => (
          <>
            <Form>
              <List>
                <ListItem>
                  <Field label="Name">
                    <TextField
                      id="name"
                      error={Boolean(touched.name && errors.name)}
                      helperText={
                        touched.name && errors.name ? errors.name : 'A recognisable name for this load balancing group'
                      }
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Field>
                </ListItem>
                <ListItem>
                  <Field label="Maximum Current">
                    <TextField
                      name="maximumCurrent"
                      error={Boolean(touched.maximumCurrent && errors.maximumCurrent)}
                      helperText={
                        errors.maximumCurrent
                          ? errors.maximumCurrent
                          : 'The maximum amount of current to be distributed among the charge points in this group, in amperes'
                      }
                      type="number"
                      InputProps={{
                        style: { width: '4em' },
                        endAdornment: <InputAdornment position="end">A</InputAdornment>,
                        inputProps: {
                          min:
                            numericSchemaParam<CreateLoadBalancingGroup>(validationSchema, 'maximumCurrent', 'min') + 1,
                          max:
                            numericSchemaParam<CreateLoadBalancingGroup>(validationSchema, 'maximumCurrent', 'max') - 1,
                        },
                      }}
                      value={values.maximumCurrent}
                      onChange={handleChange}
                    />
                  </Field>
                </ListItem>
                <ListItem>
                  <Field label="Enabled">
                    <Switch id="enabled" color="primary" checked={values.enabled} onChange={handleChange} />
                  </Field>
                </ListItem>
                <ListItem>
                  <Field label="Charge Points">
                    <ChargePointMultiSelect
                      chargePoints={orgChargePoints}
                      onChangeSelection={(selected) => setFieldValue('chargePoints', selected)}
                      fieldInputProps={{ name: 'chargePoints' }}
                      preSelectedChargePoints={preSelectedChargePoints ?? []}
                    />
                  </Field>
                  {errors.chargePoints ? <FormHelperText>{errors.chargePoints}</FormHelperText> : null}
                </ListItem>
              </List>
              <FormActions>
                <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Update load balancing group'}
                </Button>
              </FormActions>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export const DetailsForm: React.FC<{
  loadBalancingGroup: SubState<'loadBalancingGroup'>;
  organisation: SubState<'organisation'>;
  refetchLoadBalancingGroup: () => void;
}> = ({ loadBalancingGroup, organisation, refetchLoadBalancingGroup }) => {
  const {
    data,
    error,
    loading,
    refetch: refetchChargePoints,
  } = useLegacyGetOrganisationChargePoints({
    orgSlug: organisation.slug,
  });

  if (loading) {
    return <Loading />;
  }
  if (error || !data) {
    return <DataErrorHandler description="Unable to load charge points" error={error} refetch={refetchChargePoints} />;
  }
  return (
    <LoadBalancingGroupDetails
      loadBalancingGroup={loadBalancingGroup}
      orgChargePoints={data.data.items}
      refetchLoadBalancingGroup={refetchLoadBalancingGroup}
    />
  );
};
